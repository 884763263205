import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import ExperienceCard from '../components/ExperienceCard.tsx';


export default function ExperiencePage() {
    return (
        <Grid container spacing={2} sx={{ display: 'flex', alignContent: 'flex-start', minHeight: { xs: '70vh', md: '90vh' } }} className='primary-color' id='experience'>
            <Grid item xs={12} md={4} sx={{ ml: { xs: 4, sm: 4, md: 2 }, display: 'flex', flexDirection: 'column', alignItems: { xs: 'start', md: 'center' }, justifyContent: 'center' }}>
                <Box>
                    <Typography variant='h4' className='font-color-for-topics'>Experience</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{}}>
                <ExperienceCard duration='Aug 2023 - Present' jobTitle='C# Developer | Full Stack Developer, ' companyName='3XPO - Sydney' responsibilities={
                    [
                        'Develop and optimise C# scripts for mobile applications, ensuring high performance and seamless user experiences.',
                        'Collaborate with cross-functional teams to design and deliver innovative features aligned with business goals.',
                        'Perform in-depth debugging and performance optimisations to enhance application stability and maintainability.',
                        'Developed and optimised web applications using Flutter, React, JavaScript, and TypeScript, leveraging JSInterop for seamless integration with other technologies while ensuring scalability and responsiveness.',
                        'Utilise AWS cloud services to streamline application deployment, scalability, and performance.',
                        'Manage and track project progress using JIRA, ensuring timely delivery of features and resolving issues efficiently.',
                        'Participated in technical discussions and collaborated with stakeholders to determine requirements and implement solutions.'
                    ]} skills={['C#', 'Flutter', 'React', 'JavaScript', 'TypeScript', 'AWS', 'JIRA', 'Unity 3D']} />

                <ExperienceCard duration='Jul 2022 - Aug 2023' jobTitle='Full Stack Web & Mobile Developer, ' companyName='AMSBB Group - Sydney' responsibilities={
                    [
                        'Designed, developed, and maintained cross-platform mobile applications using Flutter, delivering an engaging user experience.',
                        'Built and optimised web applications with React, JavaScript, and TypeScript, ensuring scalability and responsiveness.',
                        'Deployed and managed cloud-based solutions on AWS, improving system reliability and performance.',
                        'Developed and maintained a custom Flutter plugin for third-party integrations, including the Zoom Meeting SDK, ensuring seamless connectivity and enhanced functionality.',
                        'Streamlined deployment processes by implementing CI/CD pipelines with GitLab, accelerating release cycles.',
                        'Utilised JIRA for project management, tracking progress, and collaborating effectively within Agile teams.'
                    ]} skills={['Flutter', 'React', 'JavaScript', 'TypeScript', 'AWS', 'GitLab', 'JIRA']} />

                <ExperienceCard duration='Jul 2020 - Apr 2022' jobTitle='Full Stack .NET Developer, ' companyName='Crystal Solution Pvt. Ltd. - Nepal' responsibilities={
                    [
                        'Designed and developed ERP web applications using ASP.NET Core, Entity Framework, and MySQL, enabling seamless business operations.',
                        'Built and maintained RESTful APIs, facilitating robust system integrations with mobile and web platforms.',
                        'Implemented Entity Framework for efficient data access, enabling faster development and simplifying database interactions.',
                        'Conducted unit and integration testing using NUnit, ensuring high code reliability.',
                        'Collaborated in an Agile development environment, participating in sprints, code reviews, and daily stand-ups.',
                        'Utilised tools like Git for version control to ensure efficient project delivery.'
                    ]} skills={['ASP.NET Core', 'Entity Framework', 'MySQL', 'RESTful APIs', 'NUnit', 'Agile', 'Git']} />
            </Grid>
        </Grid>
    );
}
